<template>
  <v-container grid-list-md pa-0 relative class="page-layout">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card>
            <v-card-title class="title">
              <v-icon>line_style</v-icon> &nbsp; New Purchase Items
              <v-spacer></v-spacer>
            </v-card-title>
            <div class="bill-no">
              <h3>Bill NO: {{ billNumber }}</h3>
            </div>

            <v-card-title>
              <v-flex xs3>
                <v-select
                  :readonly="form.loading"
                  v-model="form.voucher_type_id"
                  :items="voucherTypes"
                  class="pa-1"
                  label="Select Voucher Type"
                  name="voucher_type_id"
                  :height="25"
                />
                <span
                  v-if="form.voucher_type_id"
                  style="
                    position: absolute;
                    margin-top: -18px;
                    font-size: 12px;
                    color: green;
                  "
                >
                  <span v-if="manualSetting"
                    >Voucher No should be entered manually.</span
                  >
                  <span v-else>Voucher No generate Automatically.</span>
                </span>
              </v-flex>
              <v-flex xs2>
                <span v-if="manualSetting">
                  <v-text-field
                    label="Voucher No"
                    v-model="form.voucher_no"
                    name="voucher_no"
                    :error-messages="form.errors.get('voucher_no')"
                    :height="25"
                    class="pa-1"
                    :readonly="form.loading"
                  />
                </span>
                <span v-else class="voucher-no">
                  {{
                    automaticVoucherNo ? "V.No: " + automaticVoucherNo : ""
                  }}</span
                >
                <!--<span class="voucher-no" v-else>V.No: {{this.form.voucher_no}}</span>-->
              </v-flex>
              <v-flex xs3>
                <v-autocomplete
                  v-model="form.ledger_head_id"
                  :items="ledgers"
                  :search-input.sync="searchL"
                  hide-no-data
                  item-text="name"
                  item-value="id"
                  label="Account Ledger(Creditor)"
                  placeholder="Start typing to Search"
                  class="pa-1"
                  persistent-hint
                  :hint="ledger_opening"
                  :height="25"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="Bill Date"
                  v-model="form.bill_date"
                  name="bill_date"
                  prepend-icon="event"
                  mask="####-##-##"
                  placeholder="YYYY-MM-DD"
                  :error-messages="form.errors.get('bill_date')"
                  :height="25"
                  class="pa-1"
                  :readonly="form.loading"
                />
              </v-flex>
              <!--<v-flex xs3>-->
              <!--<v-text-field-->
              <!--label="Due Date" v-model="form.due_date" name="due_date"-->
              <!--prepend-icon="event"-->
              <!--class="pa-1"-->
              <!--mask="####-##-##" placeholder="YYYY-MM-DD"-->
              <!--:error-messages="form.errors.get('due_date')" :height="25"-->
              <!--:readonly="form.loading"/>-->
              <!--</v-flex>-->
              <v-flex xs3>
                <v-text-field
                  label="Purchase Bill Date"
                  v-model="form.purchase_bill_date"
                  name="purchase_bill_date"
                  prepend-icon="event"
                  class="pa-1"
                  mask="####-##-##"
                  placeholder="YYYY-MM-DD"
                  :error-messages="form.errors.get('purchase_bill_date')"
                  :height="25"
                  :readonly="form.loading"
                />
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="Purchase Bill No"
                  v-model="form.purchase_bill_no"
                  name="purchase_bill_no"
                  :error-messages="form.errors.get('purchase_bill_no')"
                  :height="25"
                  class="pa-1"
                  :readonly="form.loading"
                />
              </v-flex>

              <v-flex xs2>
                <v-select
                  :readonly="form.loading"
                  v-model="form.bill_type"
                  :items="billTypes"
                  class="pa-1"
                  label="Select Bill Type"
                  name="bill_type"
                  :height="25"
                />
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  :disabled="!form.bill_type || !form.purchase_bill_no"
                  label="Select Purchase Bill"
                  @click="onPickFile"
                  v-model="form.fileName"
                  prepend-icon="image"
                ></v-text-field>
                <input
                  type="file"
                  style="display: none"
                  ref="fileInput"
                  accept="image/*"
                  @change="onFilePicked"
                />
                <!-- <v-btn
                  color="primary"
                  @click.stop="onUploadSelectedFileClick"
                  :loading="loading"
                  >UPLOAD</v-btn -->
              </v-flex>
              <v-icon
                v-if="form.fileName"
                right
                small
                @click="removeFile"
                style="background: red; color: white"
                >cancel</v-icon
              >
            </v-card-title>
          </v-card>
          <div class="itemWrap">
            <v-layout row wrap>
              <v-flex xs2>
                <v-autocomplete
                  v-model="addItemData.item_id"
                  :items="items"
                  :search-input.sync="searchI"
                  hide-no-data
                  item-text="name"
                  item-value="id"
                  label="Item"
                  placeholder="Start typing to Search"
                  class="pa-1"
                  :height="25"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs2>
                <v-select
                  :readonly="form.loading"
                  v-model="addItemData.unit_id"
                  :items="units"
                  class="pa-1"
                  label="Unit"
                  name="unit_id"
                  :height="25"
                  :disabled="!addItemData.item_id"
                />
              </v-flex>
              <v-flex xs2>
                <!-- <v-autocomplete
                  v-model="addItemData.warehouse_id"
                  :items="warehouses"
                  :search-input.sync="searchW"
                  hide-no-data
                  item-text="name"
                  item-value="id"
                  label="Ware House"
                  :disabled="!addItemData.item_id"
                  placeholder="Start typing to Search"
                  class="pa-1"
                  :height="25"
                ></v-autocomplete> -->
                <v-select
                  :readonly="form.loading"
                  v-model="addItemData.warehouse_id"
                  :items="warehouse"
                  class="pa-1"
                  label="Warehouse"
                  name="warehouses_id"
                  :height="25"
                  :disabled="!addItemData.item_id"
                />
              </v-flex>

              <v-flex xs1>
                <v-text-field
                  v-model="addItemData.qty"
                  name="qty"
                  label="Quantity"
                  class="pa-1"
                  :error-messages="form.errors.get('qty')"
                  :height="25"
                  :disabled="!addItemData.warehouse_id"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs1>
                <v-text-field
                  v-model="addItemData.rate"
                  name="rate"
                  label="Rate"
                  class="pa-1"
                  :error-messages="form.errors.get('rate')"
                  :height="25"
                  :disabled="!addItemData.warehouse_id"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  v-model="addItemData.amount"
                  name="qty"
                  label="Amount"
                  class="pa-1"
                  :error-messages="form.errors.get('amount')"
                  :height="25"
                  :disabled="!addItemData.warehouse_id"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex>
                <v-btn
                  v-if="update"
                  style="margin-top: 10px"
                  color="success"
                  outline
                  flat
                  @click="updateItem(addItemData)"
                  :disabled="
                    !(
                      addItemData.warehouse_id &&
                      addItemData.rate &&
                      addItemData.qty &&
                      addItemData.amount
                    )
                  "
                >
                  Update
                </v-btn>
                <v-btn
                  v-else
                  style="margin-top: 10px"
                  color="success"
                  outline
                  flat
                  @click="addItem(addItemData)"
                  :disabled="
                    !(
                      addItemData.warehouse_id &&
                      addItemData.rate &&
                      addItemData.qty &&
                      addItemData.amount
                    )
                  "
                >
                  OK
                </v-btn>
              </v-flex>
              <div v-if="addItemData.item_id">
                <span class="span-title">Purchase Rate:</span>
                <span class="span-qty">{{ addItemData.purchase_rate }}</span>
              </div>
              <div v-if="addItemData.warehouse_id">
                <span class="span-title">Stock Quantity:</span>
                <span class="span-qty">{{ stock_qty }}</span>
              </div>
            </v-layout>
          </div>
          <v-data-table
            :headers="headers"
            :items="form.item_data"
            hide-actions
            class="tb"
          >
            <template slot="items" slot-scope="props">
              <td>{{ ++props.index }}</td>
              <td class="text-xs-left">
                {{ props.item.item_name }}
              </td>
              <td class="text-xs-left">
                <v-chip
                  v-if="props.item.is_taxable === 1"
                  color="green"
                  text-color="white"
                  class="chipH"
                >
                  Yes
                </v-chip>
                <v-chip v-else color="red" class="chipH" text-color="white"
                  >No</v-chip
                >
              </td>
              <td class="text-xs-left">
                <!-- {{ props.item.warehouse_name }} -->
                {{
                  warehouses.find((item) => item.id == props.item.warehouse_id)
                    .name
                }}
              </td>
              <td class="text-xs-left">{{ props.item.qty }}</td>
              <td class="text-xs-left">{{ props.item.rate }}</td>
              <td class="text-xs-left">{{ props.item.unit_name }}</td>
              <td class="text-xs-left">{{ props.item.amount }}</td>
              <td class="text-xs-right">
                <v-icon
                  small
                  @click="editItem(props.item, props.index)"
                  style="margin-right: 2px; background: blue; color: white"
                >
                  edit
                </v-icon>

                <v-icon
                  small
                  @click="removeItem(props.item)"
                  style="background: red; color: white"
                  >clear
                </v-icon>
              </td>
            </template>
            <template v-slot:footer v-if="form.item_data.length">
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">Sub Total:</td>
                <td :colspan="2">{{ sub_total }}</td>
              </tr>
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">Dis %</td>
                <td :colspan="2" class="text-xs-right">
                  <input
                    type="number"
                    @keyup="discountPercentage"
                    class="discountText"
                    v-model="form.discount_in_percentage"
                  />
                </td>
              </tr>
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right" width="20px">
                  Dis Amount:
                </td>
                <td :colspan="2">
                  <input
                    type="number"
                    @keyup="discountAmount"
                    class="discountText"
                    v-model="form.discount_in_amount"
                  />
                </td>
              </tr>
              <!--                            <tr class="footerRow">-->
              <!--                                <td :colspan="7" class="text-xs-right">Taxable Amount:</td>-->
              <!--                                <td :colspan="2">{{taxable_amount_after_discount}}</td>-->
              <!--                            </tr>-->
              <!--                            <tr class="footerRow">-->
              <!--                                <td :colspan="7" class="text-xs-right">Non Taxable Amount:</td>-->
              <!--                                <td :colspan="2">{{non_taxable_amount_after_discount}}</td>-->
              <!--                            </tr>-->
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">
                  VAT 13%:
                  <input
                    v-model="vatCheckBox"
                    type="checkbox"
                    style="width: 20px; height: 16px"
                  />
                </td>
                <td :colspan="2">{{ tax_amount }}</td>
              </tr>
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">Total:</td>
                <td :colspan="2">
                  {{ total_amount }}
                  <!--taxable: {{taxable_item_count}}-->
                  <!--no taxable: {{non_taxable_item_count}}-->
                </td>
              </tr>
              <tr class="footerRow">
                <td>Narration:</td>
                <td :colspan="8" width="100%">
                  <textarea
                    v-model="form.narration"
                    :rows="3"
                    class="footerTextarea"
                    :error-messages="form.errors.get('narration')"
                  ></textarea>
                </td>
              </tr>
              <tr class="footerRowNarration">
                <td :colspan="headers.length" class="text-xs-right">
                  <v-btn color="warning" outline flat @click="form.reset()">
                    Cancel
                  </v-btn>
                  <v-btn color="success" outline flat @click="save">Save</v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
  data: () => ({
    loading: false,

    form: new Form(
      {
        bill_type: "",
        discount_in_percentage: 0,
        discount_in_amount: 0,

        bill_date: "",
        purchase_bill_no: "",
        purchase_bill_date: "",
        ledger_head_id: "",
        voucher_type_id: "",
        voucher_no: "",
        fileName: "",
        sub_total: 0,
        taxable_amount_after_discount: 0,
        non_taxable_amount_after_discount: 0,
        total_amount: 0,
        vat_amount: 0,
        narration: "",
        bill_img: "",

        item_data: [
          // {
          //     warehouse_id: '',
          //     is_taxable: '',
          //     item_id: '',
          //     qty: '',
          //     rate: '',
          //     amount: '',
          //     item_name: '',
          //     warehouse_name: '',
          // }
        ],
        notify: {
          min_qty: "",
          max_qty: "",
          reorder_level: "",
        },
      },
      "/api/account/inventory/purchase"
    ),

    vatCheckBox: false,
    searchI: null,
    billNumber: "",
    searchL: null,
    searchW: null,
    wareHouseSearchData: [],
    itemSearchData: [],
    ledgerSearchData: [],
    voucherTypes: [],
    manualSetting: false,
    automaticVoucherNo: "",
    addItemData: {
      warehouse_id: "",
      is_taxable: "",
      item_id: "",
      qty: "",
      rate: "",
      amount: "",
      item_name: "",
      warehouse_name: "",
    },
    units: [],
    openings: [],
    sub_total: 0,
    taxable_amount: 0,
    non_taxable_amount: 0,
    tax_amount: 0,
    total_amount: 0,
    taxable_item_count: 0,
    non_taxable_item_count: 0,
    individual_discount: 0,
    taxable_amount_after_discount: 0,
    non_taxable_amount_after_discount: 0,

    editDataResetFlag: true,

    stock_qty: 0,
    ledger_opening: "",

    update: false,
    headers: [
      { text: "#", align: "left", sortable: false, value: "id", width: "20px" },
      { text: "Item", value: "item", align: "left" },
      { text: "Vatable", value: "item", align: "left", width: "20px" },
      { text: "Ware House", value: "warehouse", align: "left" },
      { text: "Qty", value: "qry", align: "left", sortable: false },
      { text: "Rate", value: "rate", align: "left", sortable: false },
      { text: "Unit", value: "unit", sortable: false, align: "left" },
      { text: "Amount", value: "amount", sortable: false, align: "left" },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "right",
        width: 120,
      },
    ],
    billTypes: [
      { value: "cash", text: "Cash" },
      { value: "credit", text: "Credit" },
    ],
    warehouse: [],
    fileObject: null,
  }),

  computed: {
    ...mapState(["batch"]),
    items() {
      return this.itemSearchData.map((entry) => {
        return { name: entry.name, id: entry.id, data: entry };
      });
    },
    ledgers() {
      return this.ledgerSearchData.map((entry) => {
        return { name: entry.name, id: entry.id, data: entry };
      });
    },

    warehouses() {
      return this.warehouse.map((entry) => {
        return { name: entry.text, id: entry.value };
      });
    },
  },
  mounted() {
    this.getVoucherTypes();
    this.getBillDate();
    this.getWarehouse();
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    "form.item_id": function (v) {
      let data = this.items.find((res) => res.id === v);
    },
    searchI(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get("api/account/inventory/item?search=" + val)
          .then(({ data }) => {
            this.itemSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id, data: res };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchL(val) {
      if (!val) return;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.$rest
          .get("api/ledger-head?search=" + val + "&type=creditors")
          .then(({ data }) => {
            this.ledgerSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id, data: res };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchW(val) {
      if (!val) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        // this.isLoading = true;
        this.$rest
          .get("api/account/inventory/warehouse?search=" + val)
          .then(({ data }) => {
            this.wareHouseSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    "addItemData.item_id": function (v) {
      if (v) {
        let itemData = this.findItem(v);
        this.addItemData.is_taxable = itemData.data.is_taxable;
        if (!this.update) {
          this.units = [
            { value: itemData.data.unit_id, text: itemData.data.unit_name },
          ];
          this.addItemData.unit_id = itemData.data.unit_id;
          this.addItemData.unit_name = itemData.data.unit_name;

          this.addItemData.purchase_rate = itemData.data.purchase_rate;
        }
        if (this.editDataResetFlag) {
          this.addItemData.warehouse_id = "";
          this.editDataResetFlag = true;
        }
        if (!this.editDataResetFlag && this.update) {
          this.editDataResetFlag = true;
        }

        //
        // this.addItemData.qty = 0;
        this.stock_qty = 0;
      }
    },
    "addItemData.warehouse_id": function (v) {
      if (v) {
        let wData = this.wareHouseSearchData.find((res) => res.id === v);
        this.addItemData.warehouse_name = wData ? wData.name : "";
        if (this.addItemData.warehouse_id && this.editDataResetFlag) {
          this.$rest
            .get(
              "/api/account/inventory/stock/qty?itemId=" +
                this.addItemData.item_id +
                "&warehouseId=" +
                this.addItemData.warehouse_id
            )
            .then((res) => {
              this.stock_qty = res.data;
            });
        }
      }

      if (!this.editDataResetFlag && this.update) {
        this.addItemData.qty = 0;
        this.addItemData.rate = 0;
      }
    },
    // 'addItemData.unit_id': function (v) {
    //     let uData = this.unitsSearchData.findUnit(res => res.id === v);
    //     this.addItemData.unit_name = uData ? uData.name : '';
    // },
    "addItemData.rate": function (v) {
      if (v && this.addItemData.qty) {
        this.addItemData.amount =
          parseFloat(v) * parseFloat(this.addItemData.qty);
      } else {
        this.addItemData.amount = 0;
      }
    },
    "addItemData.qty": function (v) {
      if (v && this.addItemData.rate) {
        this.addItemData.amount =
          parseFloat(v) * parseFloat(this.addItemData.rate);
      } else {
        this.addItemData.amount = 0;
      }
    },
    "addItemData.amount": function (v) {
      if (v && this.addItemData.qty) {
        this.addItemData.rate =
          parseFloat(v) / parseFloat(this.addItemData.qty);
      }
    },
    "form.voucher_type_id": function (v) {
      let d = this.voucherTypes.filter((data) => {
        if (data.value == v) {
          return data;
        }
      });

      if (d.length && d[0].setting == "manual") {
        this.manualSetting = true;
        this.form.voucher_no = "";
      } else {
        if (v) {
          this.$rest.get("/api/journal-type-voucher/" + v).then((data) => {
            this.automaticVoucherNo = data.data;
            this.form.voucher_no = this.automaticVoucherNo;
          });
        }
        this.manualSetting = false;
      }
    },
    "form.ledger_head_id": function (v) {
      this.$rest.get("/api/ledger-head/opening/" + v).then((res) => {
        if (res.data.length) {
          if (res.data > 0)
            this.ledger_opening =
              "openinging Balance(Dr):" + Math.abs(res.data);
          else
            this.ledger_opening =
              "openinging Balance(Dr):" + Math.abs(res.data);
        } else this.ledger_opening = "openinging Balance: 0";
      });
    },
    vatCheckBox: function (v) {
      this.calculation();
    },
  },

  methods: {
    editData(data) {
      this.categorySearchData = [];
      this.categorySearchData.push({
        name: data.parent_name,
        id: data.parent_id,
      });
    },
    save() {
      this.form.bill_date = this.form.bill_date.dateForm();
      this.form.purchase_bill_date = this.form.purchase_bill_date.dateForm();
      this.form.total_amount = this.total_amount;
      this.form.vat_amount = this.tax_amount;
      this.form.sub_total = this.sub_total;
      this.form.taxable_amount_after_discount =
        this.taxable_amount_after_discount;
      this.form.non_taxable_amount_after_discount =
        this.non_taxable_amount_after_discount;
      this.form
        .store()
        .then((res) => {
          this.getBillDate();
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    searchItem: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
    },
    addItem(item) {
      if (item.warehouse_id && item.qty && item.rate) {
        item.warehouse_name = this.findWareHouse(item.warehouse_id);
        item.item_name = this.findItem(item.item_id).name;
        // item.unit_name = this.findUnit(item.unit_id).name;
        this.form.item_data.push(item);
        this.update = false;
        this.addItemdataReset();
        this.calculation();
      }
    },
    removeItem: function (item) {
      var index = this.form.item_data.indexOf(item);
      this.form.item_data.splice(index, 1);
      this.addItemdataReset();
      this.update = false;
    },
    editItem(item, index) {
      this.editDataResetFlag = false;

      // this.wareHouseSearchData = [{name: item.warehouse_name, id: item.warehouse_id}];
      this.update = true;
      this.editIndex = index - 1;
      this.addItemData.qty = item.qty;
      this.addItemData.rate = item.rate;
      this.addItemData.is_taxable = item.is_taxable;
      this.addItemData.amount = item.amount;
      this.addItemData.purchase_rate = item.purchase_rate;
      this.addItemData.warehouse_id = item.warehouse_id;
      this.addItemData.warehouse_name = item.warehouse_name;

      this.addItemData.unit_id = item.unit_id;
      this.addItemData.item_id = item.item_id;

      this.searchW = item.warehouse_name;
      this.wareHouseSearchData.push({
        name: item.warehouse_name,
        id: item.warehouse_id,
      });
      this.units = [{ text: item.unit_name, value: item.unit_id }];
      this.itemSearchData.push({ name: item.item_name, id: item.item_id });
    },
    updateItem() {
      this.fillAddItemData(this.editIndex, this.addItemData);
      this.update = false;
      this.addItemdataReset();
      this.calculation();
      // this.form.item_data[this.editIndex].warehouse_name = this.addItemData.warehouse_name
      // this.form.item_data[this.editIndex].warehouse_name = this.addItemData.warehouse_name
    },
    findWareHouse(warehouse_id) {
      return this.warehouses.find((res) => res.value === warehouse_id);
    },
    findUnit(unit_id) {
      return this.units.find((res) => res.id === unit_id);
    },
    findItem(item_id) {
      let data = this.itemSearchData.filter((res) => {
        if (res.id === item_id) return res;
      });
      return data[0];
    },
    getVoucherTypes() {
      this.$rest.get("/api/journal-type?prType=purchase").then((res) => {
        this.voucherTypes = res.data.data.map((res) => {
          return {
            value: res.id,
            text: res.name,
            setting: res.setting.voucher_numbering,
          };
        });
      });
    },

    discountPercentage() {
      if (this.form.discount_in_percentage > 100)
        this.form.discount_in_percentage = 100;
      this.form.discount_in_amount = (
        (this.sub_total * this.form.discount_in_percentage) /
        100
      ).toFixed(2);
      this.calculation();
    },
    discountAmount() {
      if (this.sub_total < this.form.discount_in_amount)
        this.form.discount_in_amount = this.sub_total;
      this.form.discount_in_percentage = (
        (this.form.discount_in_amount / this.sub_total) *
        100
      ).toFixed(2);
      this.calculation();
    },
    calculationbackup() {
      this.sub_total = 0;
      this.taxable_amount = 0;
      this.non_taxable_amount = 0;
      this.taxable_item_count = 0;
      this.non_taxable_item_count = 0;

      this.form.item_data.map((res) => {
        this.sub_total += parseFloat(res.amount);
        if (res.is_taxable) {
          this.taxable_item_count++;
          this.taxable_amount += parseFloat(res.amount);
        }
        if (!res.is_taxable) {
          this.non_taxable_amount += parseFloat(res.amount);
          this.non_taxable_item_count++;
        }
      });

      let individual_discount_amount = (
        parseFloat(this.form.discount_in_amount) / this.form.item_data.length
      ).toFixed(2);
      let discount_for_taxable_amount = (
        this.taxable_item_count * individual_discount_amount
      ).toFixed(2);
      let discount_for_non_taxable_amount = (
        this.non_taxable_item_count * individual_discount_amount
      ).toFixed(2);

      this.taxable_amount_after_discount = (
        parseFloat(this.taxable_amount) -
        parseFloat(discount_for_taxable_amount)
      ).toFixed(2);
      if (["", "NaN", null].includes(this.taxable_amount_after_discount)) {
        this.taxable_amount_after_discount = 0;
      }

      this.non_taxable_amount_after_discount = (
        parseFloat(this.non_taxable_amount) - discount_for_non_taxable_amount
      ).toFixed(2);
      if (["", "NaN", null].includes(this.non_taxable_amount_after_discount)) {
        this.non_taxable_amount_after_discount = 0;
      }

      this.tax_amount = (
        parseFloat(this.taxable_amount_after_discount) * 0.13
      ).toFixed(2);
      this.total_amount = (
        parseFloat(this.taxable_amount_after_discount) +
        parseFloat(this.non_taxable_amount_after_discount) +
        parseFloat(this.tax_amount)
      ).toFixed(2);

      if (["", NaN, null].includes(this.tax_amount)) this.tax_amount = 0;
      if (["", NaN, null].includes(this.total_amount)) this.total_amount = 0;
    },
    calculation() {
      this.sub_total = 0;
      this.taxable_amount = 0;
      this.non_taxable_amount = 0;
      this.taxable_item_count = 0;
      this.non_taxable_item_count = 0;
      this.tax_amount = 0;

      this.form.item_data.map((res) => {
        this.sub_total += parseFloat(res.amount);
      });
      let amountAfterDiscount = this.sub_total - this.form.discount_in_amount;

      if (this.vatCheckBox) {
        this.tax_amount = amountAfterDiscount * 0.13;
      } else {
        this.tax_amount = 0;
      }

      this.total_amount = amountAfterDiscount + this.tax_amount;
    },
    addItemdataReset() {
      this.addItemData = {
        warehouse_id: "",
        is_taxable: 0,
        item_id: "",
        qty: 0,
        rate: 0,
        amount: 0,
        item_name: "",
        warehouse_name: "",
      };
    },
    fillAddItemData(index, item) {
      // this.form.item_data[index]
      this.form.item_data[index].qty = item.qty;
      this.form.item_data[index].rate = item.rate;
      this.form.item_data[index].amount = item.amount;
      this.form.item_data[index].warehouse_id = item.warehouse_id;
      this.form.item_data[index].warehouse_name = item.warehouse_name;
      this.form.item_data[index].is_taxable = item.is_taxable;

      this.form.item_data[index].unit_id = item.unit_id;
      this.form.item_data[index].item_id = item.item_id;
    },
    getBillDate() {
      this.$rest
        .get("/api/account/inventory/purchase/bill-no-date")
        .then((res) => {
          this.form.bill_date = res.data.bill_date;
          this.billNumber = res.data.bill_no;
        });
    },
    getWarehouse() {
      this.$rest.get("/api/account/inventory/warehouse").then(({ data }) => {
        this.warehouse = [];
        data.data.map((item) => {
          this.warehouse.push({ value: item.id, text: item.name });
        });
      });
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      if (files[0] !== undefined) {
        this.form.fileName = files[0].name;
        // Check validity of file
        if (this.form.fileName.lastIndexOf(".") <= 0) {
          return;
        }
        this.form.bill_img = files[0];
        // If valid, continue
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.url = fr.result;
          this.fileObject = files[0]; // this is an file that can be sent to server...
        });
      } else {
        this.fileName = "";
        this.fileObject = null;
        this.url = "";
      }
    },
    removeFile() {
      this.form.fileName = "";
    },
  },
};
</script>
<style lang="scss">
.itemWrap {
  margin: 25px;
  border: 1px solid #ccc;
  padding: 10px;
}

.discountText {
  height: 25px;
  border: 1px solid #bbb;
  text-align: left;
  /*width: 75%;*/
  width: 100%;
}

/*.discountPer {*/
/*height: 25px;*/
/*border: 1px solid #bbb;*/
/*width: 105%;*/
/*!*width: 60px;*!*/
/*!* padding-bottom: 0px; *!*/
/*text-align: center;*/
/*!*width: 109px;*!*/
/*!*margin-right: 40px;*!*/
/*}*/

.dis_form_container {
  /*margin-top: 20px;*/
  /*background-color: red;*/
  display: flex;

  .dis_form_label {
    flex: 2;
    padding: 15px;
    /*border-right: 1px dashed #eee;*/
    /*background-color: green;*/
  }

  .dis_form_input {
    flex: 1;
    padding: 5px;
    /*background-color: orange;*/
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.footerRow {
  height: 25px !important;
  border: none !important;
}

.footerRowNarration {
  border: none !important;
}

.footerTextarea {
  border: 1px solid #bbb;
  text-align: left;
  /*width: 75%;*/
  width: 100%;
}

.chipH {
  height: 20px;
}

.tb tr:last-child {
  border-bottom: 1px solid black;
}

.span-title {
  color: red;
  padding-top: 15px;
  padding-right: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.span-qty {
  color: green;
}

.voucher-no {
  color: green;
}

.bill-no {
  margin-left: 25px;
  color: #666;
  margin-bottom: -20px;
}
</style>
